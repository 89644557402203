import React from 'react';
import Logo from './Logo';

const ServerError = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="mb-8 mt-6">
        <Logo className="w-1/3 md:w-1/4 lg:w-1/5" />
      </div>

      <h2 className="text-4xl font-bold text-red-600 dark:text-red-500">
        Server Under Maintenance
      </h2>
      <p className="text-2xl text-red-500 dark:text-red-400 mb-4">
        Our servers are currently down for maintenance. Please try again soon.
      </p>
      <p className="text-xl text-red-500 dark:text-red-400 mb-4">
        We Apologize for the Inconvenience Caused.
      </p>
    </div>
  );
};

export default ServerError;
