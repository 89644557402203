import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from '../../_helpers/Logo';
import Icon from '../../_helpers/Icon';
import SecondaryHeader from './SecondaryHeader';
import VideoComponet from '../../_components/VideoComponent';
import ZamceVideo1 from "../../assets/videos/zamace_video_1.mp4";
import ZamceVideo2 from "../../assets/videos/zamace_video_2.mp4";

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Membership', href: 'membership' },
  { name: 'Exchange Operations', href: 'operations' },
  { name: 'Market Data', href: 'market-data' },
  { name: 'Investors', href: 'invest' },
  { name: 'Technology', href: 'technology' },
  { name: 'About Us', href: 'about-us' },
  { name: 'Contact', href: 'contact-us' },
];

const secondaryNavigation = [
  { name: 'Registered Brokers', href: 'brokers' },
  { name: 'Certified Warehouse Operators', href: 'warehouses' },
  { name: 'Service Providers', href: 'providers' },
  { name: 'Financial Institutions', href: 'finance' }
];

export default function Frontpage () {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [secMobileMenuOpen, setSecMobileMenuOpen] = useState(false);

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <SecondaryHeader />
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">ZAMACE</span>
              <Logo />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 mr-1"
              onClick={() => setSecMobileMenuOpen(true)}
            >
              <span className="sr-only">Find A</span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
              </svg>
            </button>
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-lg font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden items-center justify-center lg:flex lg:flex-1 lg:justify-end">
            <a href="https://dash.zamace.co.zm" class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              Dashboard Login
              <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
              </svg>
            </a>

          </div>
        </nav>

        {/* Secondary Navigation */}
        <Dialog as="div" className="lg:hidden" open={secMobileMenuOpen} onClose={setSecMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-green-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">ZAMACE</span>
                <Logo />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-green-700"
                onClick={() => setSecMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-green-500/10">
                <div className="space-y-2 py-6">
                  {secondaryNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>

        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-green-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">ZAMACE</span>
                <Logo />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-green-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-green-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="login"
                    className="rounded-md bg-green-600 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    Dashboard Login
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
          <Icon />
        </div>
        <div className="mx-auto max-w-2xl pt-32 pb-2 sm:pt-48 sm:py-24 lg:pt-56 lg:py-12">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <a href="market-data" class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-black bg-green-100 rounded-full dark:bg-green-900 dark:text-green-300 hover:bg-green-200 dark:hover:bg-green-800">
              <span class="text-xs bg-red-600 rounded-full text-white px-4 py-1.5 mr-3">Live Data</span> <span class="text-sm font-medium">View live free market data by clicking here</span>
              <svg aria-hidden="true" class="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
            </a>
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              ZAMACE is focused on <span className="text-green-700">revolutionising</span> Zambia’s and Africa's <span className="text-green-700">commodities</span> sector.
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              The <span className="text-green-700 text-bold">Zambian Commodities Exchange (ZAMACE)</span> is a commodity exchange focused on enhancing market access, liquidity and credibility in the commodities market.
            </p>
            <br />
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="register"
                className="rounded-md bg-green-600 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Start Member Registration
              </a>
              <a href="about-us" className="text-md font-semibold leading-6 text-gray-900">
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
        <div className='mx-auto w-full md:w-11/12 max-w-5xl py-10 sm:pb-24 lg:pb-42'>
          <div class="max-w-xl mt-8 mx-auto text-center">
            <h2 class="text-xl font-medium leading-tight text-gray-700 sm:text-4xl lg:text-5xl">About ZAMACE</h2>
          </div>
          <div className='flex flex-col w-full md:flex-row mt-8'>
            <div className='w-full m-auto h-60 sm:h-80 p-2'>
              <VideoComponet type="iframe" src={"https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2F100083349207509%2Fvideos%2F407292218372201%2F&show_text=false"} />
            </div>
            <div className='w-full m-auto h-60 sm:h-80 p-2'>
              <VideoComponet type="iframe" src={"https://www.youtube.com/embed/Pf4uzB4ZUFU?si=2FJ9OvQrtO282_sm?rel=0"} />
            </div>
          </div>
          <div className='flex flex-col w-100 md:flex-row'>
            <div className='w-full m-auto h-60 sm:h-80 p-2'>
              <VideoComponet type="video" src={ZamceVideo1} loop={true} />
            </div>
            <div className='w-full m-auto h-60 sm:h-80 p-2'>
              <VideoComponet type="video" src={ZamceVideo2} loop={true} />
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
          <Logo />
        </div>
      </div>
    </div>
  );
}

